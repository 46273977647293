import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Diensten from "../components/mainpage/diensten"
import Padding from "../components/padding"










const ServiceContainer = styled.div`
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: -175px;
`

const ServiceItem1 = styled.div`
  width: 250px;
  height: 300px;
  padding: 30px;
  background-color:var(--green-primary);
  p,h4{
    color: var(--white-text);
    font-weight: 400;
  }
  h4{
    font-size: 20px; 
  }
  
`
const Parent = styled.div`
  background-color: rgba(200,20,20,0.4);
  border: solid 2px red;
  width: 1000px;
  height: 700px;
  position: static;
`
const Child = styled.div`
  width: 500px;
  height: 350px;
  background-color: rgba(20,200,20,0.4);
  border: 2px solid green;
  margin: 0 auto;

  
`
const Child2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
const Childschild = styled.div`
  background-color: rgba(20,20,200,0.4);
  width: 50%;
  height: 50%;
  position: static;
  border: 2px solid blue;
`




class Dienstenpagina extends React.Component {

  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }

  render() {

    return (
      <Layout>
        <SEO title="Home" />
        <Diensten></Diensten>
        <Padding></Padding>
      </Layout>
    )
  }
}






export default Dienstenpagina